<template>

<div class="case">

	<div class="case-icon" />

	<div class="case-text" v-html="text" />

</div>

</template>

<script>

export default {

	props: ['text']

}

</script>

<style scoped>

.case {
	width: 100%;
	max-width: 1000px;
	min-height: 100px;
	margin: 0px auto;
	color: #45464B;
	font-size: 16px;
	line-height: 28px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;
}

.is-device .case {
	font-size: 14px;
}

.is-mobile .case {
	line-height: 20px;
	margin-bottom: 10px;
}

.case-icon {
	width: 55px;
	background-color: orange;
	flex-shrink: 0;
	border-radius: 6px 0px 0px 6px;
	z-index: 2;
}

.is-mobile .case-icon {
	width: 40px;
}

.case-icon:before {
	content: '';
	width: 60px;
	height: 60px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAgVBMVEUAAADp7P7p7f7o7P/n6/3o7P7o7P7p7f/p7P/o6/3o6/3o7f7n7f7o8P/k6vro7f/o7P7o7P7o7P3o7v/p7f/s7P/q6v/o7P7/LQf0jILp4O/5XET7UTX2dGPxpKH8RCX+OBbr1N/uvMD+ORfsyND1gHPvsLHymJL4aFT4aVTuvcFTdSdvAAAAF3RSTlMAZPq8PPLlwq2lmnNUIRfg1MuHWEcpDPoE5L8AAAP6SURBVGjevNRZDoMwDARQbySEULpB5/437X+LEFnMu4BlecZUbDF+JX2EQQAZwkPTi20hX5bHO3bdx2zkY+FxwKFh5O6bf1hxivKH+rFJcJpMRn3MikI6U7v3ExWeb2qzRlSKK9W7JTRIN6qUBU0kU41N0Uw3KsaCDoSp0IROprJURXQTCzJmAR0Fo5NmdDbTKYzuuH6u/2SGC667r/+dDW7ssL8BbsJRnyMcxaM/6WoqC7R/tDeBM9loj8Kd0o6MC+SdJgkuIP+dSrhEoh/rl7iy200YhmJwVCiCIfE74bQkoQltobz/Aw6dTVSosDgbEt91FTc5tg/SqC+FcPFI4/Mf1VG67qhvHK3DALpGtqDx9qbaa3vQbO+E1/RtrX5IU4Jk/adl6PrbVvsrVX/rFkLaghwlXvfQBPzQNgf9jS2R2iJjUtdoYR9wR9hrwZRI3MwfKbomYEAwA2VmS+2yBN0CD2kSlLNd0jrsxFEOT3CibNn1yFvrJLo1nlKLw12CvRYg8JKjXyPjJFUeBAv+pbvBfIcU+krHv/UUcYLECBEkVgFxpiKcI85ZX6kRoWb9lbPt4ckTpdk82SFz1tKe/MET4szJERuZMPmhIYe8JEpLEkqnnaivJZfiwI4OnvX1gvGWBLQCRSVxjzOm6sP2I2ai3FAVsiFPs6A4k/+4UTNSuABFQQrP1Og9wiO1eq1wQwqv1IQRfr25Jion38+AwpCPk6sMcVzfR0zHtYiTKRDU7HFo6Y77Ys5cdhqGgSiaJk2pQOoLxLWdBDsvEvH/HwgSmI0j9YSy6NlmMfFkPLlzJxMh0Ivc4B6X5bQt9A5kuqdvmGclzuDEqtB0QsW1w7n2Dh3Yi7DLTrAhkWsy4v+2TnBQdAGovc7gA6ugpstkribbRc3PTJiLGHUylC0OdbMYF2xgtiGNnMb1ToxNlD7wShk/aJHBm1gFiG0Ue7RjL9e2s8bgio5j23FtZP+WPPg+bngX5RgFPYwcovcy6JePsTfxK2D2K13btjY/9NVov2gqE5mdOE+rFy9TMAlJ/qHxU2oNzi6EDlarKJPBnHmocxJ50Cr2f96BdLapKu+rqrFjahZAK+Kg2/DxBnMOwG6Cs2TvxCmIwUYNkkacDbAU+f+jEyV/BSYq1ye1KI/INub1ZQXZMqOc11crRPGfe/KGuYnp7vys23C8vs58/cPFoADPfOHFxaCu88BWfBxnrSUK5OVelpqf3dvBDQAwDMJAdf+lO0MbQCY78OMcu3E5x3XmqmfhhADHoAEUN7khIiMrq2JCMsm0SWjvkHmkA4TSCawa/TYwZyHs7qHsErzflisMA43OJOU3wunOjl5Dqx1pmS2my+eDF/lZ/a1T/wW3AAAAAElFTkSuQmCC');
	background-size: 60px 60px;
	display: block;
	position: absolute;
	right: -30px;
	top: 50%;
	margin-top: -30px;
}

.is-mobile .case-icon:before {
	width: 50px;
	height: 50px;
	background-size: 50px 50px;
	right: -25px;
	margin-top: -25px;
}

.case-text {
	background-color: #fff;
	border-radius: 0px 6px 6px 0px;
	flex-grow: 1;
	padding: 20px 20px 20px 60px;
	display: flex;
	align-items: center;
}

.is-mobile .case-text {
	padding-left: 40px;
}

.case-text >>> a {
	color: #485CC7;
}

.case-text >>> a:hover {
	text-decoration: underline;
}

.case-text >>> b,
.case-text >>> strong {
	font-weight: bold;
}

.case-text >>> em,
.case-text >>> i {
	font-style: italic;
}

</style>
